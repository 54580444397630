import { RightOutlined } from '@ant-design/icons'
import { useMatches } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';

function BreadCrumb() {
  const { state } = useContext(AuthContext);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const matches = useMatches();
  let crumbs = matches.filter((match) => Boolean(match.handle?.crumb)).map((match) => match.handle.crumb(match.data));

  useEffect(() => {
    setIsDarkMode(state.isDarkMode);
  },[state]);
    return(
    <div className={`px-5 mt-4 flex gap-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-600'}`}>
      {
        crumbs.map((item, index) => (
          <div className={` ${crumbs.length-1 === index ? 'text-blue-500 hover:text-blue-400' : 'text-gray-400 hover:text-blue-400'}`}>
            {item}<span className='ml-2'>{crumbs.length-1 === index ? '' : <RightOutlined className='text-gray-400 hover:text-gray-400'/>}</span>
          </div>
        ))
      }
    </div>
  );
}

export default BreadCrumb;