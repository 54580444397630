import React, { useEffect, useState } from 'react';
import { Form as AntForm, Modal, Button } from 'antd';
import { Form } from 'components';
import { instance } from 'utils/axios';
import fields from './fields';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

function RegistrationDetail() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loanType, setLoanType] = useState([]);
  const [visaType, setVisaType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = AntForm.useForm();
  const navigate = useNavigate();
  const answer = [
    { label: "Ирээгүй", text: "Ирээгүй", value: "0" },
    { label: "Зөвшөөрсөн", text: "Зөвшөөрсөн", value: "1" },
    { label: "Татгалзсан", text: "Татгалзсан", value: "2" },
  ];
  const gender = [
    { label: "Эрэгтэй", text: "Эрэгтэй", value: "Эрэгтэй" },
    { label: "Эмэгтэй", text: "Эмэгтэй", value: "Эмэгтэй" },
  ];
  const days = [
    { label: "7 хоног", text: "7 хоног", value: 7 },
    { label: "20 хоног", text: "20 хоног", value: 20 },
    { label: "80 хоног", text: "80 хоног", value: 80 },
  ];
  const specials = [
    { label: "Энгийн", text: "Энгийн", value: "Энгийн" },
    { label: "Диплом эх хувь", text: "Диплом эх хувь", value: "Диплом эх хувь" },
    { label: "Яаралтай", text: "Яаралтай", value: "Яаралтай" },
    { label: "Хуурамч", text: "Хуурамч", value: "Хуурамч" },
    { label: "Сэжигтэй", text: "Сэжигтэй", value: "Сэжигтэй" },
    { label: "Өөрийн хүсэлтээр", text: "Өөрийн хүсэлтээр", value: "Өөрийн хүсэлтээр" },
    { label: "Гадны орчуулга", text: "Гадны орчуулга", value: "Гадны орчуулга" },
    { label: "Элчингээс залгасан", text: "Элчингээс залгасан", value: "Элчингээс залгасан" },
    { label: "Дахин мэдүүлж байгаа", text: "Дахин мэдүүлж байгаа", value: "Дахин мэдүүлж байгаа" },
    { label: "Гэрчилгээтэй", text: "Гэрчилгээтэй", value: "Гэрчилгээтэй" },
    { label: "Эмнэлгийн бичигтэй", text: "Эмнэлгийн бичигтэй", value: "Эмнэлгийн бичигтэй" },
  ];

  useEffect(() => {
    fetchData();
    fetchLoanType();
    fetchVisaType();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: `/burtgel/${id}`,
    }).then((res) => {
      setData(res.data);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };

  const fetchLoanType = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: '/loan_type',
    }).then((res) => {
      let tmp = [];
      res.data.map((item) =>
        tmp.push({ value: item.id, label: item.title, text: item.title })
      );
      setLoanType(tmp);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };

  const fetchVisaType = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: '/visa_type',
    }).then((res) => {
      let tmp = [];
      res.data.map((item) => {
        let childTmp = [];
        item.child.map((c) => childTmp.push({ value: c.id, title: c.title }))
        tmp.push({ disabled: true, value: item.id, title: item.title, children: childTmp })
      }
      );
      setVisaType(tmp);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };

  const handleSubmit = (values) => {
    setLoading(true);
    if (id === 'add') {
      instance({
        method: 'post',
        url: '/burtgel',
        data: {
          ...values,
        }
      }).then((res) => {
        fetchData();
        navigate(-1);
      }).catch((err) => {
      }).then(() => setLoading(false))
    } else {
      instance({
        method: 'put',
        url: '/burtgel',
        data: {
          ...values,
          id: id,
        }
      }).then((res) => {
        fetchData();
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  };

  return (
    <>
      <div className='border border-gray-200 rounded-lg shadow p-3 bg-white'>
        <div className='flex justify-between items-center pb-4'>
          <div className='text-primary text-lg'>Бүртгэл {id === 'add' ? 'нэмэх' : 'засах'}</div>
        </div>
        <Form
          form={form}
          fields={fields({
            answer: answer,
            loanType: loanType,
            visaType: visaType,
            gender: gender,
            specials: specials,
            id: id,
            days: days,
          })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={ data && {
            ...data,
            hariu_irsen_eseh: data?.hariu_irsen_eseh?.toString(),
          }}
          className='gap-4'
        />
        <div className="flex justify-end gap-2 mb-16">
          <Button className='flex items-center' size='small' type="default" onClick={() => navigate(-1)}>
            Буцах
          </Button>
          <Button loading={loading} className='flex items-center' size='small' type="primary" onClick={() => form.submit()}>
            Хадгалах
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegistrationDetail;