export default ({ id, loanType, gender, visaType, answer, specials }) => {
  return [
    {
      label: '',
      name: 'hariu_irsen_eseh',
      type: 'select',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        options: answer
      }
    },
  ]
}