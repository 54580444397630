import React, { useState } from 'react';
import { Form, Button } from 'antd';
import { Link } from 'react-router-dom';
// import { AuthContext } from 'contexts';
import axios from 'axios';
import CompanyForm from './CompanyForm'
import UserForm from './UserForm'

const Register = () => {
  const [ loading, setLoading ] = useState(false)
  const [ type, setType ] = useState(0)
  // const authContext = React.useContext(AuthContext)

  const handleSubmit = (values) => {
    setLoading(true)
    axios({
      method: 'post',
      url: `api/public/login`,
      data: values
    }).then(res => {
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }

  return (
    <div className='container mx-auto'>
      <div className="flex flex-col gap-4 md:gap-10 p-4 md:p-0 min-h-screen">
        <div className="flex justify-end">
          <Link className="underline py-2 px-4">
            Тусламж хэрэгтэй юу ?
          </Link>
        </div>
        <div className="grow py-10 grid grid-cols-12 h-full">
          <div className="col-span-12 md:col-start-4 md:col-span-6 h-full">
            <p className="md:text-3xl font-bold text-center">
              Хэрэглэгчийн цахим систем
            </p>
            <p className="text-center text-slate-400">
              Таны бүртгэлтэй имэйл-д нэвтрэх нэр болон код очих болно.
            </p>
            <div className="flex justify-center mb-6 md:mb-0">
              <Button type={type === 0 ? 'primary': 'default'} onClick={() => setType(0)} size="large" className="flex-1 rounded-2xl rounded-tr-none rounded-br-none h-16 text-xl">Хувь хүн</Button>
              <Button type={type === 1 ? 'primary': 'default'} onClick={() => setType(1)} size="large" className="flex-1 rounded-2xl rounded-tl-none rounded-bl-none h-16 text-xl">Байгууллага</Button>
            </div>
            <Form
              className="md:mt-20"
              onFinish={handleSubmit}>
              {
                type === 0 ?
                <UserForm laoding={loading}></UserForm>
                :
                <CompanyForm loading={loading}></CompanyForm>
              }
            </Form>
            <div className="flex justify-end">
              <Link to="/login" className="underline px-4 py-2">
              Нэвтрэх хэсэг
            </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <Link className="py-2 px-4 underline">
            Та өөрийн эрчим хүчний хэрэглээ, төлбөр төлөх ол <span className="text-primary font-bold">ЭНД</span> дарна уу,
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
