export default ({ mainType }) => {
  return [
    {
      label: 'Үндсэн төрөл',
      name: 'parentId',
      type: 'select',
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: mainType,
      }
    },
    {
      label: 'Төрлийн нэр',
      name: 'title',
      rules: [{required: true, message: 'Төрлийн нэр оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Тайлан дээр харагдах нэр',
      name: 'reportTitle',
      rules: [{required: true, message: 'Тайлан дээр харагдах нэр оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Эрэмбэ',
      name: 'orderId',
      type: 'number',
      rules: [{required: true, message: 'Эрэмбэ оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}