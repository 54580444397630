import React, { useEffect } from 'react';
import { AuthContext } from 'contexts';
import { useNavigate, Outlet } from 'react-router-dom';
import Layouts from 'layouts';

const RootPage = ({}) => {
  const authContext = React.useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(!authContext.state.loading && !authContext.state.token) {
      navigate('/admin/login');
    };
  }, [authContext.state.loading])
  if(authContext.state.loading) {
    return (
      <div>
        loading
      </div>
    )
  }
  if(authContext.state.token) {
    return (
      <Layouts>
        <Outlet></Outlet>
      </Layouts>
    );
  }
  else {
    return (
      <Outlet>
      </Outlet>
    )
  }
};

export default RootPage;
