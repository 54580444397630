import { 
  BiUser,
  BiNews,
  BiFile,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import { GrVisa } from "react-icons/gr";
import { CiBank } from "react-icons/ci";
import { MdOutlineAppRegistration } from "react-icons/md";
import { Divider } from "antd";

export default [
  {
    label: 'Систем',
    key: 'system',
    type: 'group',
    role: ['Админ', 'Захирал'],
    children: [
      {
        label: <Link to='/admin/systemuser'>Админ</Link>,
        key: 'system user',
        icon: <BiUser size={16} className="inline"/>,
      },
      {
        label: <Link to='/admin/visa'>Визний төрөл</Link>,
        key: 'visa',
        icon: <GrVisa size={16} className="inline" />,
      },
      {
        label: <Link to='/admin/loan'>Барьцаа</Link>,
        key: 'loan',
        icon: <CiBank size={16} className="inline" />,
      },
    ]
  },
  {
    label: 'Вэб',
    key: 'web',
    type: 'group',
    role: ['Админ', 'Оператор', 'Захирал'],
    children: [
      {
        label: <Link to='/admin/webvisa'>Виз</Link>,
        key: 'webvisa',
        icon: <GrVisa size={16} className="inline" />,
      },
      {
        label: <Link to='/admin/news'>Мэдээлэл</Link>,
        key: 'news',
        icon: <BiNews size={16} className="inline" />,
      },
      {
        label: <Link to='/admin/pages'>Хуудас</Link>,
        key: 'pages',
        icon: <BiNews size={16} className="inline" />,
      },
    ]
  },
  {
    label: <Divider></Divider>,
    role: ['Админ', 'Захирал'],
  },
  {
    label: <Link to='/admin/registration'>Бүртгэл</Link>,
    key: 'registration',
    role: ['Админ', 'Захирал', 'Хариу олгогч', 'Бүртгэлийн ажилтан'],
    icon: <MdOutlineAppRegistration size={16} className="inline" />,
  },
  {
    label: <Link to='/admin/report'>Тайлан</Link>,
    key: 'report',
    role: ['Админ', 'Захирал', 'Бүртгэлийн ажилтан'],
    icon: <BiFile size={16} className="inline" />,
  },
]
