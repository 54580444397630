export default ({ answer }) => {
  return [
    {
      label: 'Жил',
      name: 'year',
      type: 'date',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        picker: "year",
      }
    },
    {
      label: 'Тусгай тэмдэглэгээ',
      name: 'tusgai',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Бүртгэлийн дугаар',
      name: 'burtgel_number',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Нэр',
      name: 'first_name',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Регистрийн дугаар',
      name: 'registeration_number',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Утасны дугаар',
      name: 'phone_number',
      type: 'number',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Хариу',
      name: 'hariu_irsen_eseh',
      type: 'select',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        options: answer
      }
    },
  ]
}