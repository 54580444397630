import React, { useEffect, useState } from 'react';
import { Form as AntForm, Modal, Button, Dropdown, Menu } from 'antd';
import { Form, MainTable } from 'components';
import { instance } from 'utils/axios';
import { 
  EditOutlined, 
  PlusCircleOutlined,
  MoreOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import fields from './fields';

function WebVisa() {
  const [ data, setData ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();
  const status = [
    {label: 'Тийм', text: 'Тийм', value: 1},
    {label: 'Үгүй', text: 'Үгүй', value: 0},
  ]
  const defaultCheckedColumns = [
    'No', 
    'title',
    'reportTitle',
    'action',
  ];

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: '/web_visa',
    }).then((res) => {
      setData(res.data);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };
  
  const handleDelete = (id) => {
    modal.confirm({
      title: 'Анхаарна уу !',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `web_visa/${id}`,
          }).then((res) => {
            resolve();
          }).catch((err) => {
            reject();
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  };
  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Нэр',
      dataIndex: 'title',
      key: 'title',
      filterType: "search",
    },
    {
      title: 'Үйлдэл',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 80,
      render: (text, row) => (
        <div className='flex justify-center w-full'>
          <Dropdown
            placement='bottomRight'
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleEdit(row)} key='action-1'>
                  <div className='flex items-center gap-2'><EditOutlined/> Засах</div>
                </Menu.Item>
                <Menu.Item danger className='flex items-center gap-2' onClick={() => handleDelete(row.id)} key='action-2'> 
                  <div className='flex items-center gap-2'><DeleteOutlined /> Устгах</div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button size='small' className='flex items-center' >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  ];
  const handleSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    let keys = Object.keys(values);
    keys.map((item) => {
      formData.append(item, values[`${item}`] || '');
    });
    formData.set('image', values.image?.file ? values.image?.file : '');
    if(editData){
      formData.append('id', editData.id);
      instance({
        method: 'put',
        url: '/web_visa',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      }).then((res) => {
        handleCloseModal();
        fetchData();
      }).catch((err) => {
      }).then(() => setLoading(false))
    } else {
      instance({
        method: 'post',
        url: '/web_visa',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      }).then((res) => {
        handleCloseModal();
        fetchData();
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      <div className='flex justify-between items-center pb-2'>
        <div className='text-primary'>Виз</div>
        <Button icon={<PlusOutlined/>} type='primary' size='small' onClick={handleAdd}>Нэмэх</Button>
      </div>
      <MainTable
        dataTable={data} 
        columns={columns} 
        setCurrentPage={setCurrentPage} 
        loading={loading} 
        defaultCheckedColumns={defaultCheckedColumns} 
        filterHide={false}
        pagination={true}
      />
      <Modal 
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Засах</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Нэмэх</div>} 
          open={open} 
          destroyOnClose 
          width={1000}
          okText='Хадгалах'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <Form
            form={form}
            fields={fields({
              status: status,
            })}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
        {contextHolder}
    </>
  );
};

export default WebVisa;