import { ConfigProvider, Layout } from 'antd';
import { Headers, Sidebars } from './components';
import { BreadCrumb } from 'components';
const { Content } = Layout;

const Layouts = ({children}) => {

  const lightTheme = {
    bodyBg: '#fafafa',
    headerBg: 'white',
    headerPadding: '0 40px',
    siderBg: 'white',
    triggerBg: 'transparent',
    triggerColor: '#000000',
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          colorText: 'black',
          colorBgContainer: 'rgb(255, 255, 255,)'
        },
        components: {
          Layout: lightTheme,
          Menu: {
            activeBarBorderWidth: 0,
            horizontalItemSelectedColor: 'transparent',
            iconSize: 24,
            fontSize: 12,
            itemBg: 'transparent',
            itemMarginInline: 0,
            itemMarginBlock: 8,
            itemPaddingInline: 16,
            darkItemBg: 'rgb(37, 46, 72)',
          },
          Table: {
            headerBorderRadius: 0,
            headerBg: 'white',
          }
        },
      }}
    >
      <Layout>
        <Headers></Headers> 
        <Layout
          hasSider
          style={{
            minHeight: '100vh',
            overflow: 'auto',
          }}
        >
          <Sidebars></Sidebars>
          <Layout
            style={{
              height: '100vh',
              overflow: 'auto',
            }}
          >
            {/* <BreadCrumb /> */}
            <Content
              style={{
                padding: 16,
                margin: 0,
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default Layouts;
