import { DatePicker, Form, Input, Select } from "antd";
export default ({ id, days, loanType, gender, visaType, answer, specials }) => {
  return [
    {
      label: 'Регистрийн дугаар',
      name: 'registeration_number',
      rules: [{required: true, message: 'Регистрийн дугаар оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Бүртгэлийн дугаар',
      name: 'burtgel_number',
      type: 'number',
      rules: [{required: true, message: 'Бүртгэлийн дугаар оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Материал хүлээн авсан огноо',
      name: 'receive_date',
      type: 'date',
      rules: [{required: true, message: 'Материал хүлээн авсан огноо оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    // {
    //   label: 'Элчинд материал өгсөн огноо',
    //   name: 'embassy_date',
    //   type: 'date',
    //   rules: [{required: true, message: 'Элчинд материал өгсөн огноо оруулна уу!'}],
    //   className: 'col-span-12 md:col-span-6 mb-0',
    //   inputProps: {
    //     className: 'w-full',
    //   }
    // },
    {
      type: 'component',
      component: <>
        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.receive_date !== curValues.receive_date }>
          {({getFieldValue, setFieldValue}) => {
            if(getFieldValue('receive_date')){
              setFieldValue('embassy_date', getFieldValue('receive_date').add(1, 'day'))
            }
            return (
              <>
                  <Form.Item
                    label='Элчинд материал өгсөн огноо' 
                    name='embassy_date'
                    className='col-span-6 mb-0' 
                    rules={[{required: true, message: 'Элчинд материал өгсөн огноо оруулна уу!'}]}
                  >
                    <DatePicker
                      className="w-full"
                      size="small"
                    />
                  </Form.Item>
              </>
            )
          }}
        </Form.Item>
      </>
    },
    {
      label: 'Овог',
      name: 'last_name',
      rules: [{required: true, message: 'Овог оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Нэр',
      name: 'first_name',
      rules: [{required: true, message: 'Нэр оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Төрсөн өдөр',
      name: 'birthday',
      type: 'date',
      rules: [{required: true, message: 'Төрсөн өдөр оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Хүйс',
      name: 'gender',
      type: 'select',
      rules: [{required: true, message: 'Хүйс сонгоно уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        options: gender
      }
    },
    {
      label: 'Пасспорт дугаар',
      name: 'passport_number',
      rules: [{required: true, message: 'Пасспорт дугаар оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Пасспорт дуусах огноо',
      name: 'pass_duusah_date',
      type: 'date',
      rules: [{required: true, message: 'Пасспорт дуусах огноо оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Хариу авах огноо',
      name: 'passport_return_day',
      type: 'select',
      rules: [{required: true, message: 'Хариу авах огноо оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        options: days
      }
    },
    {
      label: 'Визний төрөл',
      name: 'visa_type_id',
      type: 'treeSelect',
      rules: [{required: true, message: 'Визний төрөл сонгоно уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        treeData: visaType,
      }
    },
    {
      label: 'Утасны дугаар 1',
      name: 'phone_number',
      type: 'number',
      rules: [{required: true, message: 'Утасны дугаар 1 оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Утасны дугаар 2',
      name: 'phone_number2',
      type: 'number',
      rules: [{required: true, message: 'Утасны дугаар 2 оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Барьцаатай эсэх',
      name: 'loan_type_id',
      type: 'select',
      rules: [{required: true, message: 'Барьцаатай эсэх сонгоно уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        options: loanType,
      }
    },
    {
      label: 'Тусгай тэмдэглэгээ',
      name: 'tusgai',
      type: 'select',
      rules: [{required: true, message: 'Тусгай тэмдэглэгээ оруулна уу!'}],
      className: 'col-span-12 md:col-span-6 mb-0',
      inputProps: {
        className: 'w-full',
        options: specials
      }
    },
    {
      label: 'Бусад тэмдэглэгээ',
      name: 'tailbar',
      type: 'textarea',
      rules: [{required: true, message: 'Бусад тэмдэглэгээ оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      type: 'component',
      component: <>
      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues !== curValues }>
        {({getFieldValue}) => {
            return (
              <>
                {
                  id > 0 &&
                  <>
                    <Form.Item 
                      label='Хариу'
                      name='hariu_irsen_eseh'
                      className='col-span-12 md:col-span-4 mb-0' 
                      rules={[{required: true, message: 'Хариу оруулна уу!'}]}
                    >
                      <Select options={answer} className="w-full" size="small"/>
                    </Form.Item>
                    <Form.Item 
                      label='Пасспорт хүлээлгэн өгсөн огноо, тайлбар'
                      name='passport_return_day'
                      className='col-span-12 md:col-span-12 mb-0' 
                      rules={[{required: true, message: 'Пасспорт хүлээлгэн өгсөн огноо, тайлбар оруулна уу!'}]}
                    >
                      <Input.TextArea className="w-full" size="small"/>
                    </Form.Item>
                  </>
                }
              </>
            )
        }}
      </Form.Item>
    </>
    },
    {
      label: 'Хураамж',
      name: 'paymment',
      type: 'price',
      rules: [{required: true, message: 'Хураамж оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      text: 'Орчуулга',
      type: 'divider',
      className: 'col-span-12 md:col-span-12 mb-0',
    },
    {
      label: 'Өргөдөл',
      name: 'or_urgudul',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 15000,
      }
    },
    {
      label: 'Ажлын тодорхойлолт',
      name: 'or_aj_tod',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 8000,
      }
    },
    {
      label: 'Түрээсийн тодорхойлолт',
      name: 'or_tur_tod',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 6000,
      }
    },
    {
      label: 'Түрээсийн гэрээ',
      name: 'or_tur_geree',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 20000,
      }
    },
    {
      label: 'Татварын тодорхойлолт',
      name: 'or_tat_tod',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 15000,
      }
    },
    {
      label: 'Гэрлэлтийн лавлагаа',
      name: 'or_get_lav',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 4000,
      }
    },
    {
      label: 'Цагийн тодорхойлолт',
      name: 'or_tsag_tod',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 4000,
      }
    },
    {
      label: 'Төрөл садан',
      name: 'or_turul_sadan',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 4000,
      }
    },
    {
      label: 'Төрсний бүртгэл',
      name: 'or_tursnii_burtgel',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 4000,
      }
    },
    {
      label: 'Бусад',
      name: 'or_busad',
      type: 'priceCheck',
      className: 'col-span-6 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
        amount: 0,
      }
    },
  ]
}