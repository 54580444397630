import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { instance } from 'utils/axios';
import moment from 'moment';
import GlassInput from 'components/GlassInput';
import GlassButton from 'components/GlassButton';

const Login = () => {
  const [ loading, setLoading ] = useState(false);
  const [ isReset, setIsReset ] = useState(false);
  const authContext = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [ form ] = Form.useForm();

  useEffect(() => {
    if(authContext.state.token) {
      navigate("/", {replace: true});
    }
  }, [authContext.state]);

  const handleSubmit = (values) => {
    setLoading(true);
    instance({
      method: 'post',
      url: `/login`,
      data: values,
    }).then(res => {
      authContext.action.login(res.data.token, res.data);
    }).catch(err => {
      form.setFields([
        {
          name: 'username',
          errors: [''],
        },
        {
          name: 'password',
          errors: [err?.response?.data?.value]
        },
      ])
    }).then(() => {
      setLoading(false);
    })
  };

  const handleSubmitReset = (values) => {
    setLoading(true);
    instance({
      method: 'post',
      url: 'resetpassword',
      data: {
        ...values,
      },
    }).then((res) => {
      setIsReset(!isReset);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };

  const handleFormChange = () => {
    form.setFields([
      {
        name: 'username',
        errors: [],
      },
      {
        name: 'password',
        errors: [],
      },
    ])
  };

  return (
    <div className='flex h-screen bg-gray-900'>
      <div aria-hidden='true' className='animate-pulse absolute inset-y-16 inset-x-0 w-16 rounded-full rotate-45 bg-gradient-to-b from-pink-500 to-purple-600 blur-3xl mx-auto scale-y-150 opacity-75'></div>
      <div className="flex-1 flex items-center justify-center">
        <div className='flex flex-col p-4 md:p-0 rounded-xl border border-gray-400 bg-white bg-opacity-10 shadow-lg '>
          <div className='flex-1 flex justify-center flex-col p-10'>
            <div className="text-2xl md:text-4xl font-bold text-white">
              Нэвтрэх хэсэг
            </div>
            <p className="text-slate-200">
              Та өөрийн бүртгэлээр нэвтрэн орно уу?
            </p>
            {
              !isReset ? 
              <Form
                form={form}
                className="md:mt-10"
                onFinish={handleSubmit}
                onValuesChange={handleFormChange}
                layout='vertical'
              >
                <Form.Item
                  name="username"
                  rules={[{required: true, message: 'Нэвтрэх нэр оруулна уу!'}]}
                >
                  <GlassInput 
                    placeholder="Нэвтрэх нэр"
                  /> 
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{required: true, message: 'Нууц үг код оруулна уу!'}]}
                  >
                  <GlassInput 
                    type="password"
                    placeholder="Нууц үг"
                  /> 
                </Form.Item>
                {/* <Form.Item>
                  <div className="flex justify-end">
                    <Link className="underline text-white" onClick={() => setIsReset(!isReset)}>
                      Нууц үгээ мартсан?
                    </Link>
                  </div>
                </Form.Item> */}
                <Form.Item>
                  <GlassButton 
                    loading={loading}
                    name="Нэвтрэх"
                    size="large"
                    htmlType="submit" 
                  />
                </Form.Item>
              </Form>
              :
              <>
                <div className='mb-4 text-xs text-gray-700 p-3 border border-blue-200 rounded-xl bg-blue-100'>Та зөвхөн өөрийн бүртгэлтэй и-мэйл хаягийг оруулснаар тухайн и-мэйл хаягт шинэ нууц үг илгээх болно</div>
                <div className='text-2xl text-orange-200 mt-5 font-bold'>Нууц үг сэргээх</div>
                <Form
                  form={form}
                  className="md:mt-10"
                  onFinish={handleSubmitReset}
                  layout='vertical'
                >
                  <Form.Item
                    name="username"
                    className='mb-3'
                    rules={[{required: true, message: 'И-Мэйл хаяг оруулна уу!'}]}
                  >
                    <GlassInput 
                    type="username"
                    placeholder="И-Мэйл хаяг"
                  /> 
                  </Form.Item>
                  <Form.Item>
                    <div className="flex justify-end">
                      <Link className="underline text-white" onClick={() => setIsReset(!isReset)}>
                        Нэвтрэх хэсэг рүү буцах
                      </Link>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <GlassButton 
                      loading={loading}
                      name="Илгээх"
                      size="large"
                      htmlType="submit" 
                    />
                  </Form.Item>
                </Form>
              </>
            }
            </div>
          <div className='py-2 text-center text-gray-200'>© {moment().format('YYYY')} Admin</div>
        </div>
      </div>
      <div className='hidden md:block flex-1 relative'>
        <div className='relative flex min-h-screen justify-center items-center'>
          <div className='text-6xl text-white font-bold uppercase'><span className='italic text-primary'>admin</span> panel</div>
        </div>
      </div>
    </div>
  );
};

export default Login;
