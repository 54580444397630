import React, { useEffect, useState } from 'react';
import menu from '../../menu';
import { Layout, Menu } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import ls from 'utils/ls';
import { AuthContext } from 'contexts';
const { Sider } = Layout;

const Sidebars = () => {
  const {state, action} = React.useContext(AuthContext);
  const [ currentKey, setCurrentKey ] = useState('');
  const [ collapsed, setCollapsed ] = useState(false);
  const [ roleMenu, setRoleMenu ] = useState([]);
  useEffect(() => {
    setCurrentKey(ls.get('currentMenuKey'));
    let tmp = [];
    menu.map((item) => {
      if(item.role.filter((el) => el === state.userInfo.customer.role)){
      // if(item.role.find((el) => el === 'Админ')){
        tmp.push(item);
      }
    });
    setRoleMenu(tmp);
  },[]);

  const handleClickMenu = (e) => {
    setCurrentKey(e.key);
    ls.set('currentMenuKey', e.key);
  };

  return (
    <Sider
      width={250}
      collapsible
      collapsed={collapsed}
      trigger={<div className={`text-right px-4 text-black`}>{collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}</div>}
      className={`border-r border-r-gray-200`}
      reverseArrow={true}
      collapsedWidth={60}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Menu
        defaultSelectedKeys={['menu-item-0']}
        mode={'inline'}
        theme={'light'}
        selectedKeys={[currentKey]}
        onClick={handleClickMenu}
        key={'menu'}
        style={{ height: '100vh'}}
        items={roleMenu}
      />
    </Sider>
  );
};

export default Sidebars;
