export default ({ role }) => {
  return [
    {
      label: 'Нэр',
      name: 'name',
      rules: [{required: true, message: 'Нэр оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Нэвтрэх нэр',
      name: 'username',
      rules: [{required: true, message: 'Нэвтрэх нэр оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Нууц үг',
      name: 'password',
      type: 'password',
      rules: [{required: true, message: 'Нууц үг оруулна уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Төрөл',
      name: 'role',
      type: 'select',
      rules: [{required: true, message: 'Төрөл сонгоно уу!'}],
      className: 'col-span-12 md:col-span-12 mb-0',
      inputProps: {
        className: 'w-full',
        options: role,
      }
    },
  ]
}