export default () => {
  return [
    {
      label: 'Огноо',
      name: 'date',
      type: 'date',
      className: 'col-span-12 md:col-span-3 mb-0',
      inputProps: {
        className: 'w-full',
      }
    },
  ]
}