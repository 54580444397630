import React, { useState, useContext, useEffect } from 'react';
import { BiLockAlt, BiUser, BiLogOut } from 'react-icons/bi';
import { Button, Modal, Form as AntForm, Menu, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts';
import logo from 'assets/images/system.png'
import { Form } from 'components';
import { instance } from 'utils/axios';
import ls from 'utils/ls';
import menu from '../../menu';

const { Header } = Layout;

const Headers = () => {
  const [ openModal, setOpenModal ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { state, action } = React.useContext(AuthContext)
  const [ currentKey, setCurrentKey ] = useState('');
  const [ form ] = AntForm.useForm();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentKey(ls.get('currentMenuKey'));
    let tmp = [];
    menu.map((item) => {
      if(state.userInfo?.forms?.find((el) => el.formcode === item.formCode)){
        tmp.push(item);
      }
    });
  },[]);

  const handleLogout = () => {
    authContext.action.logout();
    ls.remove('userInfo') ;
    navigate("/admin/login");
  };

  const fields = [
    {
      label: 'Хуучин нууц үг',
      name: 'password',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Хуучин нууц үг оруулна уу'}]
    },
    {
      label: 'Шинэ нууц үг',
      name: 'newPassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Шинэ нууц үг оруулна уу'}]
    },
    {
      label: 'Шинэ нууц үг давтах',
      name: 'confirmPassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Шинэ нууц үг давтан оруулна уу'}]
    },
  ]

  const handleSubmitChangePassword = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: 'userpassword',
      data: {
        password: values.password,
        newPassword: values.newPassword,
      }
    }).then(() => {
      setOpenModal(false)
    }).catch((err) => {
    }).then(() => setLoading(false)) 
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    form.resetFields();
  };

  const items = [
    {
      label: <BiUser size={30} />,
      key: 'SubMenu',
      children: [
        {
          label: 'Хувийн тохиргоо',
          align: 'center',
          type: 'group',
          children: [
            {
              label: <div className='flex items-center gap-2' onClick={() => setOpenModal(true)}><BiLockAlt size={20}/>Нууц үг солих</div>,
              key: 'changepassword',
            },
            {
              label: <div className='flex items-center gap-2' onClick={handleLogout}><BiLogOut size={20}/>Гарах</div>,
              key: 'logout',
            },
          ],
        }
      ],
    },
  ];
  return (
    <Header className={`flex items-center justify-between border-b border-b-gray-200`}>
      <div className='flex gap-2 items-center'>
        {/* <img src={logo} height={40} alt='logo' className='mx-auto' /> */}
        <div className='text-xl'>{process.env.REACT_APP_PROJECT_NAME} бүртгэлийн систем</div>
      </div>
      <Menu
        style={{border: 'none'}}
        mode="horizontal"
        selectable={false}
        items={items} 
      />
      <Modal
        title={<div className='text-lg'>Нууц үг солих</div>}
        open={openModal} 
        onCancel={handleCloseModal}
        width={700}
        destroyOnClose  
        footer={false}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmitChangePassword}
          fields={fields}
          className='gap-4'
        />
        <div className='flex justify-end mt-4'>
          <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
        </div>
      </Modal>
    </Header>
  );
}

export default Headers;
