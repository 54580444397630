import { Button } from 'antd';

const GlassButton = ({ loading, name, type="primary", size, htmlType="button" }) => {
  return (
    <Button
      loading={loading} 
      disabled={loading} 
      block 
      type={type}
      size={size}
      htmlType={htmlType}
      
      className="
        middle 
        none 
        center 
        rounded-md 
        bg-primary 
        py-3 
        px-6 
        text-xs 
        font-semibold 
        text-white 
        shadow-md 
        shadow-primary/20 
        transition-all 
        hover:shadow-lg 
        hover:shadow-primary/40 
        focus:opacity-[0.85] 
        focus:shadow-none 
        active:opacity-[0.85] 
        active:shadow-none 
        disabled:pointer-events-none 
        disabled:opacity-50 
        disabled:shadow-none"
    >
      {name}
    </Button> 
  );
};

export default GlassButton;