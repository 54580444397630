import { Button, Checkbox, Col, Input, Modal, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { SearchOutlined, FilterOutlined, ControlOutlined } from '@ant-design/icons';


const MainTable = ({ dataTable, columns, setCurrentPage, setSelectedRows, loading, defaultCheckedColumns=[], expandable=null, filterHide=false, rowSelectionShow=false, pagination=false, setSelectedKeys, selectedKeys, title, borderHide=false, vs=null, scroll, summary=null, components=null }) => {
  const [ mainColumns, setMainColumns ] = useState([]);
  const [ open, setOpen ] = useState(false);
  const [ checkedColumn, setCheckedColumn] = useState(defaultCheckedColumns);

  useEffect(() => {
    let columnItem = [];
    columns.map((fooValue) => {
          columnItem.push({
          ...fooValue,
          ...renderFilter(fooValue),
        });
    });
    setMainColumns(columnItem);
    },[columns]);

    const onChangeColumns = (checkedValues) => {
      setCheckedColumn(checkedValues);
    };

    const rowSelections = {
      type: "checkbox",
      selectedRowKeys: selectedKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedKeys(selectedRowKeys);
        setSelectedRows(selectedRows)
        // console.log(
        //   `selectedRowKeys: ${selectedRowKeys}`,
        //   "selectedRows: ", selectedRows
        // );
        }
      };

    const renderFilter = (_data) => {
      switch (_data?.filterType) {
          case "select":
              return {
                filterMode: "menu",
                filterSearch: true,
                onFilter:(value, record) => {
                  return parseInt(record[_data.dataIndex].id) === parseInt(value)
                },
                filterIcon: () => {
                  return <FilterOutlined />
                },
              }
          case "status":
              return {
                filterMode: "menu",
                onFilter:(value, record) => {
                  return parseInt(record[_data.dataIndex]) === parseInt(value)
                },
                filterIcon: () => {
                  return <FilterOutlined />
                },
              }
          case "search":
              return {
                filterDropdown:({ setSelectedKeys, selectedKeys, confirm, clearFilters  })=> {
                  return <div className='flex flex-col rounded-lg p-2'>
                    <Input 
                    autoFocus 
                    placeholder={`${_data.title} хайх`}
                    value={selectedKeys[0]}
                    onChange={(e)=> {
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                      confirm({ closeDropdown: false });
                    }}
                    onPressEnter={()=> {
                      confirm()
                    }}
                    onBlur={() => {
                      confirm()
                    }}
                    />
                  </div>
                },
                // sortDirections: ['descend', 'ascend'],
                // sorter: (value, record) => {
                //   console.log("value",parseInt(value.odd))
                //   console.log("record",parseInt(record.odd))
                //   return value.odd.localeCompare(record.odd)
                // },
                
                // sortDirections: ['descend', 'ascend'],
                // sorter: (value, record) => {
                //   console.log("value",parseInt(value[_data.dataIndex]))
                //   console.log("record",parseInt(record[_data.dataIndex]))
                //   return typeof value === 'string' ? 
                //   value[_data.dataIndex].localeCompare(record[_data.dataIndex])
                //   : typeof value === 'number' ? 
                //   parseInt(value[_data.dataIndex]) - parseInt(record[_data.dataIndex])
                //   : null
                // },
                filterIcon: () => {
                  return <SearchOutlined />
                },
                onFilter: (value, record) =>{
                  return record[_data.dataIndex]
                  ? record[_data.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                  : ''
                },
              }
          default:
              return (
                  <></>
              )
      }
  };
    return (
      <div className={`${borderHide ? '' : `border border-gray-200 rounded-lg shadow`} bg-white`}>
        {
          (title || !filterHide) &&
          <div className={`flex flex-row py-3 px-3 ${title ? 'justify-between' : 'justify-end'}`}>
            {
              title && title
            }
            {
              !filterHide ? 
              <Tooltip placement='left' title="Багана шүүх"> 
                <Button 
                  className='border-none' 
                  size='small'
                  type="default" 
                  onClick={() => setOpen(true)} 
                >
                  <ControlOutlined className='text-xl' />
                </Button>
              </Tooltip>
              : <></>
            }
          </div>
        }
        <Table
          className={filterHide ? '' : `border-t border-gray-200`}
          rowKey={components ? 'index' : (row) => row.id}
          summary={summary ? summary : null}
          rowClassName={ 
              vs ? 
              vs :
              (record, index) =>  index % 2 === 0 ? 'table-row-dark' :  'table-row-light'
          }
          columns={mainColumns.filter((col) => checkedColumn.includes(col.dataIndex))}
          dataSource={dataTable}
          loading={loading}
          size='small'
          components={components}
          scroll={scroll ? scroll : {x: 'auto'}}
          expandable={expandable}
          rowSelection={rowSelectionShow && rowSelections}
          pagination={pagination && {
            defaultPageSize: 20,
            onChange: (e) => setCurrentPage((e-1)*20),
            showSizeChanger: dataTable?.length > 20
          }}
        />
        <Modal
          title={<div className='flex items-center justify-start gap-2'><ControlOutlined />Багана шүүх</div>}
          open={open} 
          destroyOnClose 
          onCancel={() => setOpen(false)}
          footer={false}
        >
          <Checkbox.Group onChange={onChangeColumns} value={checkedColumn}>
            <Row gutter={[16, 16]}>
            {
              columns.map((colItem, colIndex) => {
                return (
                <Col key={colIndex} span={12}>
                  <Checkbox disabled={colItem.dataIndex === "No" ? true : colItem.dataIndex === "action" ? true : false } value={colItem.dataIndex}>{colItem.title}</Checkbox>
                </Col>
                )
              })
            }
            </Row>
          </Checkbox.Group>
        </Modal>
      </div>
      );
    };
    
    export default MainTable;
