import React, { useEffect, useState } from 'react';
import { Tag, Form as AntForm, Tooltip, Modal, Button, Dropdown, Menu, List } from 'antd';
import { Form, MainTable } from 'components';
import { instance } from 'utils/axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { 
  EditOutlined, 
  PlusCircleOutlined,
  MoreOutlined,
  DeleteOutlined,
  PlusOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import fields from './fields';

function Visa() {
  const [ data, setData ] = useState([]);
  const [ mainType, setMainType ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();

  const status = [
    {label: "Active", text: "Active", value: 1}, 
    {label: "Inactive", text: "Inactive", value: 0},
  ];

  const defaultCheckedColumns = [
    'No', 
    'parentId',
    'title',
    'reportTitle',
    'action',
  ];

  useEffect(() => {
    fetchData();
    fetchMainTypeData();
  },[]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: '/visa_type',
    }).then((res) => {
      setData(res.data);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };

  const fetchMainTypeData = () => {
    setLoading(true);
      instance({
        method: 'get',
        url: '/visa_type_select',
      }).then((res) => {
        let tmp = [];
        res.data.map((item) =>
          tmp.push({ value: item.id, label: item.title, text: item.title})
        );
        setMainType(tmp);
      }).catch((err) => {
      }).then(() => setLoading(false))
  };
  
  const handleDelete = (id) => {
    modal.confirm({
      title: 'Анхаарна уу !',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `visa_type/${id}`,
          }).then((res) => {
            resolve();
          }).catch((err) => {
            reject();
          }).then(() => {fetchData(); fetchMainTypeData()})
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  };
  const columns = [
    // {
    //   title: 'No',
    //   dataIndex: 'No',
    //   key: 'No',
    //   width: 40,
    //   align: 'center',
    //   render: (text, row, i) => (
    //     <span>{currentPage+i+1}</span>
    //   )
    // },
    {
      title: 'Нэр',
      dataIndex: 'title',
      key: 'title',
      filterType: "search",
    },
    {
      title: 'Үйлдэл',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 80,
      render: (text, row) => (
        <div className='flex justify-center w-full'>
          <Dropdown
            placement='bottomRight'
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleEdit(row)} key='action-1'>
                  <div className='flex items-center gap-2'><EditOutlined/> Засах</div>
                </Menu.Item>
                <Menu.Item danger className='flex items-center gap-2' onClick={() => handleDelete(row.id)} key='action-2'> 
                  <div className='flex items-center gap-2'><DeleteOutlined /> Устгах</div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button size='small' className='flex items-center' >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  ];
  const handleSubmit = (values) => {
    setLoading(true);
    if(editData){
      instance({
        method: 'put',
        url: '/visa_type',
        data: {
          ...values,
          id: editData.id,
        }
      }).then((res) => {
        handleCloseModal();
        fetchData();
        fetchMainTypeData();
      }).catch((err) => {
      }).then(() => setLoading(false))
    } else {
      instance({
        method: 'post',
        url: '/visa_type',
        data: {
          ...values,
        }
      }).then((res) => {
        handleCloseModal();
        fetchData();
        fetchMainTypeData();
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      <div className='flex justify-between items-center pb-2'>
        <div className='text-primary'>Визний төрөл</div>
        <Button icon={<PlusOutlined/>} type='primary' size='small' onClick={handleAdd}>Нэмэх</Button>
      </div>
      <MainTable
        dataTable={data} 
        columns={columns} 
        setCurrentPage={setCurrentPage} 
        loading={loading} 
        defaultCheckedColumns={defaultCheckedColumns} 
        filterHide={false}
        pagination={true}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <CaretDownOutlined onClick={e => onExpand(record, e)} />
          ) : (
            <CaretRightOutlined onClick={e => onExpand(record, e)} />
          ),
          expandedRowRender: (childs) => (
            <div className='pl-16 pr-4'>
              {
                childs.child.length > 0 ?
                childs.child.map((item, i) => (
                  <div className='flex w-full items-center justify-between'>
                    <div>{item?.title}</div>
                    <Dropdown
                      placement='bottomRight'
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleEdit(item, childs)} key='action-1'>
                            <div className='flex items-center gap-2'><EditOutlined/> Засах</div>
                          </Menu.Item>
                          <Menu.Item danger className='flex items-center gap-2' onClick={() => handleDelete(item.id)} key='action-2'> 
                            <div className='flex items-center gap-2'><DeleteOutlined /> Устгах</div>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <Button size='small' className='flex items-center' >
                        <MoreOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                ))
                : <>--</>
              }
            </div>
          ),
          rowExpandable: (data) => data !== 'Empty data',
        }}
      />
      <Modal 
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Засах</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Нэмэх</div>} 
          open={open} 
          destroyOnClose 
          width={400}
          okText='Хадгалах'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <Form
            form={form}
            fields={fields({
              mainType: mainType
            })}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
        {contextHolder}
    </>
  );
};

export default Visa;