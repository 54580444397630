import React, { useEffect, useState } from 'react';
import { Form as AntForm, Modal, Dropdown, Menu, Button } from 'antd';
import { 
  EditOutlined, 
  PlusCircleOutlined,
  MoreOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Form, MainTable } from 'components';
import { instance } from 'utils/axios';
import fields from './fields';

function SystemUser() {
  const [ data, setData ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const [ modal, contextHolder ] = Modal.useModal();
  const [ form ] = AntForm.useForm();
  const role = [
    {label: "Бүртгэлийн ажилтан", text: "Бүртгэлийн ажилтан", value: "Бүртгэлийн ажилтан"}, 
    {label: "Хариу олгогч", text: "Хариу олгогч", value: "Хариу олгогч"}, 
    {label: "Захирал", text: "Захирал", value: "Захирал"}, 
    {label: "Оператор", text: "Оператор", value: "Оператор"}, 
    {label: "Админ", text: "Админ", value: "Админ"}, 
  ];
  const defaultCheckedColumns = [
    'No', 
    'name', 
    'username', 
    'role', 
    'action', 
  ];

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: '/user',
    }).then((res) => {
      setData(res.data);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Анхаарна уу !',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `user/${id}`,
          }).then((res) => {
            resolve();
          }).catch((err) => {
            reject();
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage+i+1}</span>
      )
    },
    {
      title: 'Нэр',
      dataIndex: 'name',
      key: 'name',
      filterType: "search",
    },
    {
      title: 'Нэвтрэх нэр',
      dataIndex: 'username',
      key: 'username',
      filterType: "search",
    },
    {
      title: 'Эрх',
      dataIndex: 'role',
      key: 'role',
      filterType: 'status',
      filters: role,
    },
    {
      title: 'Үйлдэл',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 80,
      render: (text, row) => (
        <div className='flex justify-center w-full'>
          <Dropdown
            placement='bottomRight'
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleEdit(row)} key='action-1'>
                  <div className='flex items-center gap-2'><EditOutlined/> Засах</div>
                </Menu.Item>
                <Menu.Item danger className='flex items-center gap-2' onClick={() => handleDelete(row.id)} key='action-2'> 
                  <div className='flex items-center gap-2'><DeleteOutlined /> Устгах</div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button size='small' className='flex items-center' >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if(editData){
      instance({
        method: 'put',
        url: '/user',
        data: {
          ...values,
          id: editData.id,
        }
      }).then((res) => {
        handleCloseModal();
        fetchData();
      }).catch((err) => {
      }).then(() => setLoading(false))
    } else {
      instance({
        method: 'post',
        url: '/user',
        data: {
          ...values,
        }
      }).then((res) => {
        handleCloseModal();
        fetchData();
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      <div className='flex justify-between items-center pb-2'>
        <div className='text-primary'>Хэрэглэгч</div>
        <Button icon={<PlusOutlined/>} type='primary' size='small' onClick={handleAdd}>Нэмэх</Button>
      </div>
      <MainTable
        dataTable={data} 
        columns={columns} 
        setCurrentPage={setCurrentPage} 
        loading={loading} 
        defaultCheckedColumns={defaultCheckedColumns} 
        filterHide={false}
        pagination={false}
      />
      <Modal 
          title={editData ? <div className='flex items-center justify-start gap-2'><EditOutlined/>Засах</div> : <div className='flex items-center justify-start gap-2'><PlusCircleOutlined/>Нэмэх</div>} 
          open={open} 
          destroyOnClose 
          width={400}
          okText='Хадгалах'
          onOk={form.submit}
          onCancel={handleCloseModal}
        >
          <Form
            form={form}
            fields={fields({
              role: role,
            })}
            layout="vertical"
            onFinish={handleSubmit}
            editData={editData}
            isEdit={isEdit}
            className='gap-4'
          />
        </Modal>
        {contextHolder}
      {/* <MainTable
        dataTable={data} 
        columns={columns} 
        setCurrentPage={setCurrentPage} 
        loading={loading} 
        defaultCheckedColumns={defaultCheckedColumns} 
        filterHide={false}
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <CaretDownOutlined onClick={e => onExpand(record, e)} />
          ) : (
            <CaretRightOutlined onClick={e => onExpand(record, e)} />
          ),
          expandedRowRender: (role) => (
            <div className='mx-8 my-2'>
              <MainTable
                dataTable={role.players ? role.players : []} 
                columns={playerColumns} 
                setCurrentPage={setCurrentPlayerPage} 
                loading={loading} 
                defaultCheckedColumns={defaultCheckedPlayerColumns} 
                filterHide={true}
                borderHide={true}
                title={' '}
                pagination={true} 
              />
            </div>
          ),
          rowExpandable: (data) => data !== 'Empty data',
        }}
      /> */}
    </>
  );
};

export default SystemUser;