import { createBrowserRouter } from "react-router-dom";
import { Register, Login } from 'modules/public';
import adminRoutes from './adminRoutes';
import RootPage from "modules/core";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    errorElement: <div className="h-screen flex items-center justify-center text-xl font-bold italic">ERROR</div>,
    children: [...adminRoutes]
    // children: [...adminRoutes].map(foo => {
    //   return {
    //     ...foo,
    //     element: <ProtectedRoute routeItem={foo}>
    //       {foo.element}
    //     </ProtectedRoute>
    //   }
    // })  
  },
  {
    path: '/admin/login',
    element: <Login></Login>
  },
  {
    path: '/admin/register',
    element: <Register></Register>
  },
])

export default router
