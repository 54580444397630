import {
  SystemUser,
  Visa,
  Loan,
  WebVisa,
  News,
  Pages,
  Registration,
  Report,
  RegistrationDetail,
} from 'modules/admin';
import { Link } from 'react-router-dom';

export default [
  {
    title: 'Админ',
    path: '/admin/systemuser',
    role: ['Админ', 'Захирал'],
    element: <SystemUser/>,
    handle: {
      crumb: () => <Link to={'/admin/systemuser'}>Админ</Link>
    }
  },
  {
    title: 'Визний төрөл',
    path: '/admin/visa',
    role: ['Админ', 'Захирал'],
    element: <Visa/>,
    handle: {
      crumb: () => <Link to={'/admin/visa'}>Визний төрөл</Link>
    },
  },
  {
    title: 'Барьцаа',
    path: '/admin/loan',
    role: ['Админ', 'Захирал'],
    element: <Loan/>,
    handle: {
      crumb: () => <Link to={'/admin/loan'}>Барьцаа</Link>
    }
  },
  {
    title: 'Виз',
    path: '/admin/webvisa',
    role: ['Админ', 'Оператор', 'Захирал'],
    element: <WebVisa/>,
    handle: {
      crumb: () => <Link to={'/admin/webvisa'}>Виз</Link>
    }
  },
  {
    title: 'Мэдээлэл',
    path: '/admin/news',
    role: ['Админ', 'Оператор', 'Захирал'],
    element: <News/>,
    handle: {
      crumb: () => <Link to={'/admin/news'}>Мэдээлэл</Link>
    }
  },
  {
    title: 'Хуудас',
    path: '/admin/pages',
    role: ['Админ', 'Оператор', 'Захирал'],
    element: <Pages/>,
    handle: {
      crumb: () => <Link to={'/admin/pages'}>Хуудас</Link>
    }
  },
  {
    title: 'Бүртгэл',
    path: '/admin/registration',
    role: ['Админ', 'Захирал', 'Хариу олгогч', 'Бүртгэлийн ажилтан'],
    handle: {
      crumb: () => <Link to={'/admin/registration'}>Бүртгэл</Link>
    },
    children: [
      {
        index: true,
        element: <Registration/>,
      },
      { 
        title: 'Бүртгэл дэлгэрэнгүй',
        path: '/admin/registration/:id',
        role: ['Админ', 'Захирал', 'Хариу олгогч', 'Бүртгэлийн ажилтан'],
        element: <RegistrationDetail />,
        loader: async ({params}) => params,
        handle: {
          crumb: (e) => <Link to={`/registration/${e.id}`}>Бүртгэл дэлгэрэнгүй</Link>
        }
      }, 
    ]
  },
  {
    title: 'Тайлан',
    path: '/admin/report',
    role: ['Админ', 'Захирал', 'Бүртгэлийн ажилтан'],
    element: <Report/>,
    handle: {
      crumb: () => <Link to={'/admin/report'}>Тайлан</Link>
    }
  },
]
