import { message } from 'antd';
import ls from 'utils/ls';

const actions = (dispatch) => ({
  login: (token, userInfo) => {
    ls.set('userInfo', userInfo)
    dispatch({type: 'LOGIN', token: token, userInfo: userInfo})
  },
  logout: async () => {
    ls.remove('userInfo')
    dispatch({type: 'LOGOUT'})
  },
  changeTabIndexSetting: async (index) => {
    dispatch({type: 'CHANGE_TAB_INDEX_SETTING', index})
  },
  
  handleDarkMode: async (isDark) => {
    dispatch({type: 'DARK_MODE', isDarkMode: isDark})
  },
  onSuccess: (info) => {
    message.success(info)
  },
  onError: (info) => {
    message.error(info)
  }
})

export default actions
