import { Input } from "antd";

const GlassInput = ({ placeholder=' ', type, ...restProps }) => {
  return (
    <div className="border-b border-gray-200">
        <Input
            {...restProps}
            type={type}
            size={"large"}
            className="
                    peer 
                    h-full 
                    w-full 
                    border-none
                    border-gray-200 
                    bg-transparent 
                    pt-4 
                    pb-1.5 
                    font-sans 
                    text-base 
                    font-normal
                    text-white 
                    outline 
                    outline-0 
                    transition-all 
                    placeholder-shown:border-gray-200 
                    focus:border-primary
                    focus:shadow-none
                    focus:outline-0 
                    disabled:border-0 
                    disabled:bg-gray-50
                    "
                placeholder=" "
            />
        <label 
            className="
                after:content[' '] 
                pointer-events-none 
                absolute 
                left-0 
                -top-1.5 
                flex 
                h-full 
                w-full 
                select-none 
                text-[11px] 
                font-normal 
                leading-tight 
                text-white 
                transition-all 
                after:absolute 
                after:-bottom-1.5 
                after:block 
                after:w-full 
                after:scale-x-0 
                after:border-b-2 
                after:border-gray-200 
                after:transition-transform 
                after:duration-300 
                peer-placeholder-shown:text-sm 
                peer-placeholder-shown:leading-[4.25] 
                peer-placeholder-shown:text-white
                peer-focus:text-[12px] 
                peer-focus:leading-tight 
                peer-focus:text-white 
                peer-focus:after:scale-x-100 
                peer-focus:after:border-primary
                peer-disabled:text-transparent 
                peer-disabled:peer-placeholder-shown:text-gray-200
            "
        >{placeholder}
        </label>
    </div>
    );
};

export default GlassInput;