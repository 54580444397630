import React, { useEffect, useState } from 'react';
import { Form as AntForm, Button, Collapse, Tabs, Tooltip, Table, Tag } from 'antd';
import {
    SearchOutlined,
    ClearOutlined,
    CaretRightOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
import { Form } from 'components';
import { instance } from 'utils/axios';
import searchFields from './searchFields';
import moment from 'moment';

const ExcelJS = require('exceljs');

const { Panel } = Collapse;
const column4 = [
    {
        title: '구분',
        dataIndex: 'title',
        key: 'title',
        filterType: "search",
    },
    {
        title: '수량',
        dataIndex: 'total',
        key: 'total',
        filterType: "search",
    },
    {
        title: '접수번호 (Agency Number)',
        dataIndex: 'registeration_number',
        key: 'registeration_number',
        render: (data, row) => (
            <div className='flex items-center gap-2 flex-wrap'>
                {
                    data?.map((item, i) => (
                        <Tag key={i}>{item}</Tag>
                    ))
                }
            </div>
        )
    },
]

const columns3 = [
    {
        title: '구분',
        dataIndex: 'visa_type',
        key: 'visa_type',
        filterType: "search",
        colSpan: 2,
        onCell: (_, index) => {
            return _.rowSpan > 0 ? {rowSpan:  _.rowSpan } : {colSpan: 0}
        },
        render: (text, row) => (
            <div>{row.reportTitle}</div>
        )
    },
    {
        title: '',
        dataIndex: 'childTitle',
        key: 'childTitle',
        colSpan: 0,
        onCell: (record) => ({
        }),
        filterType: "search",
    },
    {
        title: '수량',
        dataIndex: 'col2',
        key: 'col2',
        children: [
            {
                title: '단수(Single)',
                dataIndex: 'Single',
                key: 'single',
                filterType: "search",
            },
            {
                title: '더블(Double)',
                dataIndex: 'Double',
                key: 'double',
                filterType: "search",
            },
            {
                title: '복수(Multi)',
                dataIndex: 'Multi',
                key: 'multi',
                filterType: "search",
            },
            {
                title: '간이(Simplified)',
                dataIndex: 'Simplified',
                key: 'simplified',
                filterType: "search",
            },
        ]
    },
    {
        title: '계',
        dataIndex: 'total',
        key: 'total',
        filterType: "search",
        onCell: (_, index) => {
            return _.rowSpan > 0 ? {rowSpan:  _.rowSpan } : {colSpan: 0}
        },
        render: (text, record) => (
            <div>{text}</div>
        )
    },
]
const columns2 = [
    {
        title: '번호',
        dataIndex: 'index',
        key: 'index',
        filterType: "search",
        render: (data, row, index) => (
            <div>{index+1}</div>
        )
    },
    {
        title: '접수일',
        dataIndex: 'embassy_date',
        key: 'embassy_date',
        filterType: "search",
    },
    {
        title: '접수 번호',
        dataIndex: 'burtgel_number',
        key: 'burtgel_number',
        filterType: "search",
    },
    {
        title: '성명',
        dataIndex: 'name',
        key: 'name',
        filterType: "search",
    },
    {
        title: '생년월일',
        dataIndex: 'birthday',
        key: 'birthday',
        filterType: "search",
    },
    {
        title: '여권번호',
        dataIndex: 'passport_number',
        key: 'passport_number',
        filterType: "search",
    },
    {
        title: '사증 유형',
        dataIndex: 'visa_type',
        key: 'visa_type',
        filterType: "search",
    },
    {
        title: '구분 1 (더블/복수)',
        dataIndex: 'visa_type1',
        key: 'visa_type1',
        filterType: "search",
    },
    {
        title: '구분 2 (간이/사증발급인정서)',
        dataIndex: 'visa_type2',
        key: 'visa_type2',
        filterType: "search",
    },
    {
        title: '수수료 (USD)',
        dataIndex: 'loan_type',
        key: 'loan_type',
        filterType: "search",
    },
    {
        title: '연락처',
        dataIndex: 'phone_number',
        key: 'phone_number',
        filterType: "search",
    },
    {
        title: '비고',
        dataIndex: 'tailbar',
        key: 'tailbar',
        filterType: "search",
    },
]
const columns1 = [
    {
        title: "번호",
        dataIndex: 'index',
        key: 'index',
        render: (data, row, index) => (
            <div>{index+1}</div>
        )
    },
    {
        title: "접수일",
        dataIndex: 'embassy_date',
    },
    {
        title: "접수 번호",
        dataIndex: 'burtgel_number',
    },
    {
        title: "성명",
        dataIndex: 'name',
    },
    {
        title: "생년월일",
        dataIndex: 'birthday',
    },
    {
        title: "여권번호",
        dataIndex: 'passport_number',
    },
    {
        title: "사증 유형",
        dataIndex: 'visa_type',
    },
    {
        title: "구분 1 (더블/복수)",
        dataIndex: 'visa_type1',
    },
    {
        title: "구분 2 (간이/사증발급인정서)",
        dataIndex: 'visa_type2',
    },
    {
        title: "수수료 (USD)",
        dataIndex: 'loan_type',
    },
    {
        title: "연락처",
        dataIndex: 'phone_number',
    },
    {
        title: "비고",
        dataIndex: 'tailbar',
    },
]

const columns4 = [
    {
        title: "구분",
        key: 'registeration_number',
        dataIndex: 'registeration_number',
    },
    {
        title: "수량",
        key: 'total',
        dataIndex: 'total',
    },
    {
        title: "접수번호 (Agency Number)",
        key: 'tusgai',
        dataIndex: 'tusgai',
    },
]

function Report() {
    const [report1, setReport1] = useState([]);
    const [report2, setReport2] = useState([]);
    const [report3, setReport3] = useState([]);
    const [report4, setReport4] = useState([]);
    const [ tableReport3, setTableReport3 ] = useState([])
    const [ tableReport4, setTableReport4 ] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filterForm] = AntForm.useForm();
    const [ date, setDate ] = useState(moment())
    
    const answer = [
        { label: "Ирээгүй", text: "Ирээгүй", value: "0" },
        { label: "Зөвшөөрсөн", text: "Зөвшөөрсөн", value: "1" },
        { label: "Татгалзсан", text: "Татгалзсан", value: "2" },
    ];

    const defaultCheckedColumns = [
        'No',
        'burtgel_number',
        'first_name',
        'hariu_date',
        'hariu_irsen_eseh',
        'last_name',
        'name',
        'phone_number',
        'receive_date',
        'registeration_number',
        'tailbar',
        'tusgai',
        'action',
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        instance({
            method: 'get',
            url: `/burtgelreport?date=${moment().format('YYYY-MM-DD')}`,
        }).then((res) => {
            let report2 = []
            res.data.report2.map((item) => {
                let total = 0
                item.child.map((children) => {
                    total += children.Double + children.Multi + children.Simplified + children.Single
                })
                item.child.map((children, i) => {
                    report2.push({
                        ...children,
                        reportTitle: item.reportTitle,
                        rowSpan: i == 0 ? item.child.length : 0,
                        total: total
                    })
                })
            })
            let report3 = []
            let keys = Object.keys(res.data.report3)
            keys.map((key) => {
                if(res.data.report3[key].length > 0){
                    let regNumbers = []
                    res.data.report3[key].map((item, i) => {
                        regNumbers.push(item.registeration_number)
                    })
                    report3.push({
                        title: key,
                        registeration_number: regNumbers,
                        total: regNumbers.length
                    })
                }
            })
            setReport1(res.data.report0);
            setReport2(res.data.report1);
            setReport3(res.data.report2);
            setReport4(res.data.report3);
            setTableReport3(report2)
            setTableReport4(report3)
        }).catch((err) => {
        }).then(() => setLoading(false))
    };

    const handleFilterSubmit = (values) => {
        console.log('haih', values);
        setLoading(true);
        setDate(values.date.format('YYYY-MM-DD'))
        instance({
            method: 'get',
            url: `/burtgelreport?date=${values.date.format('YYYY-MM-DD')}`,
        }).then((res) => {
            let report2 = []
            res.data.report2.map((item) => {
                let total = 0
                item.child.map((children) => {
                    total += children.Double + children.Multi + children.Simplified + children.Single
                })
                item.child.map((children, i) => {
                    report2.push({
                        ...children,
                        reportTitle: item.reportTitle,
                        rowSpan: i == 0 ? item.child.length : 0,
                        total: total
                    })
                })
            })
            let report3 = []
            let keys = Object.keys(res.data.report3)
            keys.map((key) => {
                if(res.data.report3[key].length > 0){
                    let regNumbers = []
                    res.data.report3[key].map((item, i) => {
                        regNumbers.push(item.registeration_number)
                    })
                    report3.push({
                        title: key,
                        registeration_number: regNumbers,
                        total: regNumbers.length
                    })
                }
            })
            setReport1(res.data.report0);
            setReport2(res.data.report1);
            setReport3(res.data.report2);
            setReport4(res.data.report3);
            console.log('filter tmp', report2);
            setTableReport3(report2)
            setTableReport4(report3)
        }).catch((err) => {
        }).then(() => setLoading(false))
    };

    const exportExcelFile = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet1 = workbook.addWorksheet('대장 1');
        const sheet2 = workbook.addWorksheet('대장 2');
        sheet1.properties.defaultRowHeight = 20;
        sheet1.columns = [
            {
                header: "",
                key: 'index',
                width: 5,
            },
            {
                header: "",
                key: 'receive_date',
                width: 15,
            },
            {
                header: "",
                key: 'burtgel_number',
                width: 15,
            },
            {
                header: "",
                key: 'name',
                width: 30,
            },
            {
                header: "",
                key: 'birthday',
                width: 15,
            },
            {
                header: "",
                key: 'passport_number',
                width: 15,
            },
            {
                header: "",
                key: 'visa_type',
                width: 15,
            },
            {
                header: "",
                key: 'visa_type1',
                width: 15,
            },
            {
                header: "",
                key: 'visa_type2',
                width: 15,
            },
            {
                header: "",
                key: 'loan_type',
                width: 15,
            },
            {
                header: "",
                key: 'phone_number',
                width: 30,
            },
            {
                header: "",
                key: 'tailbar',
                width: 30,
            },
        ];

        sheet1.mergeCells("A2:L2")
        sheet1.getCell("A2").value = `"M" 공인대행기관 사증접수대장 (${process.env.REACT_APP_PROJECT_NAME} LLC)`
        sheet1.getCell("A2").alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet1.getCell("A2").font = {size: 20, bold: true}
        sheet1.getCell("A2").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'}
        }

        sheet1.mergeCells("A4:B4")
        sheet1.getCell("A4").value = `접수일:`
        sheet1.getCell("A4").font = { size: 20, bold: true}

        sheet1.mergeCells("C4:E4")
        sheet1.getCell("C4").value = moment(date).format("YYYY-MM-DD")
        sheet1.getCell("C4").alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet1.getCell("C4").font = { size: 20, bold: true}

        sheet1.mergeCells("J4:K4")
        sheet1.mergeCells("J5:K5")
        sheet1.getCell("J4").value = "KVAC Staff Stamp/Signature:"	
        sheet1.getCell("J4").font = { size: 11, bold: true}
        sheet1.getCell("J5").value = "Agency Staff Signature:"	
        sheet1.getCell("J5").font = { size: 11, bold: true}

        sheet1.getCell("J4").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }
        sheet1.getCell("L4").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }
        sheet1.getCell("J5").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }
        sheet1.getCell("L5").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }
        
        sheet1.mergeCells('A7:L7')
        sheet1.getCell('A7').value = '가. C-3-9가 아닌 신청인'
        
        sheet1.getCell(`A8`).value = '번호';
        sheet1.getCell(`B8`).value = '접수일';
        sheet1.getCell(`C8`).value = '접수 번호';
        sheet1.getCell(`D8`).value = '성명';
        sheet1.getCell(`E8`).value = '생년월일';
        sheet1.getCell(`F8`).value = '여권번호';
        sheet1.getCell(`G8`).value = '사증 유형';
        sheet1.getCell(`H8`).value = '구분 1 (더블/복수)';
        sheet1.getCell(`I8`).value = '구분 2 (간이/사증발급인정서)';
        sheet1.getCell(`J8`).value = '수수료 (USD)';
        sheet1.getCell(`K8`).value = '연락처';
        sheet1.getCell(`L8`).value = '비고';
        sheet1.getCell(`A8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`B8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`C8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`D8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`E8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`F8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`G8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`H8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`I8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`J8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`K8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        sheet1.getCell(`L8`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}

        report1?.map((report, index) => {
            sheet1.spliceRows(9+index, 1, {
                index: index+1,
                receive_date: moment(date).format("YYYY-MM-DD"),
                burtgel_number: report?.burtgel_number,
                name: report?.name,
                birthday: moment(report?.birthday).format('YYYY-MM-DD'),
                passport_number: report?.passport_number,
                visa_type: report?.visa_type,
                visa_type1: report?.visa_type1,
                visa_type2: report?.visa_type2,
                loan_type: report?.loan_type,
                phone_number: report?.phone_number,
                tailbar: report?.tailbar,
            });
            sheet1.getCell(`A${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`B${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`C${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`D${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`E${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`F${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`G${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`H${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`I${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`J${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`K${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`L${9+index}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        });

        sheet1.mergeCells(`A${report1.length+9}:L${report1.length+9}`)
        sheet1.getCell(`A${report1.length+9}`).value="나. C-3-9 신청인 (복수 자격 포함)"
        
        report2?.map((report, index) => {
            sheet1.spliceRows(report1.length+9+index+1,1,{
                index: index+1,
                receive_date: moment(date).format("YYYY-MM-DD"),
                burtgel_number: report?.burtgel_number,
                name: report?.name,
                birthday: moment(report?.birthday).format('YYYY-MM-DD'),
                passport_number: report?.passport_number,
                visa_type: report?.visa_type,
                visa_type1: report?.visa_type1,
                visa_type2: report?.visa_type2,
                loan_type: report?.loan_type,
                phone_number: report?.phone_number,
                tailbar: report?.tailbar,
            });
            sheet1.getCell(`A${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`B${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`C${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`D${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`E${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`F${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`G${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`H${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`I${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`J${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`K${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
            sheet1.getCell(`L${report1.length+9+index+1}`).border = {top: {style: 'thin', color: 'black'},right: {style: 'thin', color: 'black'},bottom: {style: 'thin', color: 'black'},left: {style: 'thin', color: 'black'},}
        });        

        sheet2.mergeCells("B2:N2")
        sheet2.getCell("B2").value = `"A" 공인대행기관 사증접수대장 (${process.env.REACT_APP_PROJECT_NAME} LLC)`
        sheet2.getCell("B2").alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell("B2").font = {size: 20, bold: true}
        sheet2.getCell("B2").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'}
        }

        sheet2.mergeCells("B4:C4")
        sheet2.getCell("B4").value = `접수일:`
        sheet2.getCell("B4").font = { size: 20, bold: true}

        sheet2.mergeCells("D4:F4")
        sheet2.getCell("D4").value = moment(date).format("YYYY-MM-DD")
        sheet2.getCell("D4").alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell("D4").font = { size: 20, bold: true}

        sheet2.mergeCells("K4:L4")
        sheet2.mergeCells("M4:N4")
        sheet2.mergeCells("K5:L5")
        sheet2.mergeCells("M5:N5")
        sheet2.getCell("K4").value = "KVAC Staff Stamp/Signature:"	
        sheet2.getCell("K4").font = { size: 11, bold: true}
        sheet2.getCell("K5").value = "Agency Staff Signature:"	
        sheet2.getCell("K5").font = { size: 11, bold: true}

        sheet2.getCell("K4").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }
        sheet2.getCell("M4").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }
        sheet2.getCell("K5").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }
        sheet2.getCell("M5").border = {
            top: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'}
        }

        sheet2.mergeCells("B6:N6")
        sheet2.getCell("B6").value = "■ 사증접수"

        sheet2.mergeCells("B7:E8")
        sheet2.getCell("B7").value = "구분"
        sheet2.getCell('B7').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell('B7').border = {
            right: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'}
        }

        sheet2.mergeCells("F7:L7")
        sheet2.getCell("F7").value = "수량"
        sheet2.getCell('F7').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell('F7').border = {
            right: {style: 'thin', color: 'black'},
            bottom: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'},
        }

        sheet2.mergeCells("M7:N8")
        sheet2.getCell("M7").value = "계"
        sheet2.getCell('M7').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell('M7').border = {
            bottom: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'},
        }

        sheet2.getCell("F8").value = "단수(Single)"
        sheet2.getCell('F8').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell('F8').border = {
            bottom: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'},
            top: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
        }

        sheet2.mergeCells("G8:H8")
        sheet2.getCell("G8").value = "더블(Double)"
        sheet2.getCell('G8').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell('G8').border = {
            bottom: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'},
            top: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
        }

        sheet2.mergeCells("I8:J8")
        sheet2.getCell("I8").value = "복수(Multi)"
        sheet2.getCell('I8').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell('I8').border = {
            bottom: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'},
            top: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
        }

        sheet2.mergeCells("K8:L8")
        sheet2.getCell("K8").value = "간이(Simplified)"
        sheet2.getCell('K8').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true}
        sheet2.getCell('K8').border = {
            bottom: {style: 'thin', color: 'black'},
            left: {style: 'thin', color: 'black'},
            top: {style: 'thin', color: 'black'},
            right: {style: 'thin', color: 'black'},
        }

        const cols = ["B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"]
        cols.map(foo => {
            sheet1.getCell(`${foo}7`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb:'fff2f2f2'},
                bgColor: {argb:'fff2f2f2'}
              };
            sheet1.getCell(`${foo}8`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb:'fff2f2f2'},
                bgColor: {argb:'fff2f2f2'}
              };
        })


        let tmp = report3.filter(foo => foo.child.length > 0)
        tmp.map((foo, fooIndex) => {
            if(fooIndex !== 0) {
                let prevChildsLength = 0;
                for(let i = fooIndex; i >= 0; i--) {
                    prevChildsLength += report3[i].child.length
                }
                sheet2.mergeCells(`B${9+prevChildsLength}:C${9+prevChildsLength+foo.child.length-1}`)
                sheet2.mergeCells(`M${9+prevChildsLength}:N${9+prevChildsLength+foo.child.length-1}`)
                sheet2.getCell(`B${9+prevChildsLength}`).value = foo.reportTitle
                sheet2.getCell(`B${9+prevChildsLength}`).border = {
                    top: {style:'thin',color:'black'},
                    left: {style:'thin',color:'black'},
                    bottom: {style:'thin',color:'black'},
                    right: {style:'thin',color:'black'}
                }
                sheet2.getCell(`M${9+prevChildsLength}`).border = {
                    top: {style:'thin',color:'black'},
                    left: {style:'thin',color:'black'},
                    bottom: {style:'thin',color:'black'},
                    right: {style:'thin',color:'black'}
                }
                foo.child.map((child, childIndex) => {
                    sheet2.mergeCells(`D${9+childIndex+prevChildsLength}:E${9+childIndex+prevChildsLength}`)
                    sheet2.getCell(`D${9+childIndex+prevChildsLength}`).value = child.childTitle
                    sheet2.getCell(`D${9+childIndex+prevChildsLength}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }
                    
                    sheet2.getCell(`F${9+childIndex+prevChildsLength}`).value = child.Single
                    sheet2.getCell(`F${9+childIndex+prevChildsLength}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }

                    sheet2.getCell(`G${9+childIndex+prevChildsLength}`).value = child.Double
                    sheet2.getCell(`G${9+childIndex+prevChildsLength}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }
                    sheet2.mergeCells(`G${9+childIndex+prevChildsLength}:H${9+childIndex+prevChildsLength}`)

                    sheet2.getCell(`I${9+childIndex+prevChildsLength}`).value = child.Multi
                    sheet2.getCell(`I${9+childIndex+prevChildsLength}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }
                    sheet2.mergeCells(`I${9+childIndex+prevChildsLength}:J${9+childIndex+prevChildsLength}`)

                    sheet2.getCell(`K${9+childIndex+prevChildsLength}`).value = child.Simplified
                    sheet2.getCell(`K${9+childIndex+prevChildsLength}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }
                    sheet2.mergeCells(`K${9+childIndex+prevChildsLength}:L${9+childIndex+prevChildsLength}`)
                })
                sheet2.getCell(`M${9+prevChildsLength}`).value = foo.child.reduce((a,b) => a+b.Double+b.Multi+b.Simplified+b.Single, 0)
            }
            else {
                sheet2.mergeCells(`B${9}:C${9+foo.child.length-1}`)
                sheet2.mergeCells(`M${9}:N${9+foo.child.length-1}`)
                sheet2.getCell(`B${9}`).value = foo.reportTitle
                sheet2.getCell(`B${9}`).border = {
                    top: {style:'thin',color:'black'},
                    left: {style:'thin',color:'black'},
                    bottom: {style:'thin',color:'black'},
                    right: {style:'thin',color:'black'}
                }
                foo.child.map((child, childIndex) => {
                    sheet2.mergeCells(`D${9+childIndex}:E${9+childIndex}`)
                    sheet2.getCell(`D${9+childIndex}`).value = child.childTitle
                    sheet2.getCell(`D${9+childIndex}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }

                    sheet2.getCell(`F${9+childIndex}`).value = child.Single
                    sheet2.getCell(`F${9+childIndex}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }

                    sheet2.getCell(`G${9+childIndex}`).value = child.Double
                    sheet2.getCell(`G${9+childIndex}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }
                    sheet2.mergeCells(`G${9+childIndex}:H${9+childIndex}`)

                    sheet2.getCell(`I${9+childIndex}`).value = child.Multi
                    sheet2.getCell(`I${9+childIndex}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }
                    sheet2.mergeCells(`I${9+childIndex}:J${9+childIndex}`)

                    sheet2.getCell(`K${9+childIndex}`).value = child.Simplified
                    sheet2.getCell(`K${9+childIndex}`).border = {
                        top: {style:'thin',color:'black'},
                        left: {style:'thin',color:'black'},
                        bottom: {style:'thin',color:'black'},
                        right: {style:'thin',color:'black'}
                    }
                    sheet2.mergeCells(`K${9+childIndex}:L${9+childIndex}`)  

                })
                sheet2.getCell(`M${9}`).value = foo.child.reduce((a,b) => a+b.Double+b.Multi+b.Simplified+b.Single, 0)
                sheet2.getCell(`M${9}`).border = {
                    top: {style:'thin',color:'black'},
                    left: {style:'thin',color:'black'},
                    bottom: {style:'thin',color:'black'},
                    right: {style:'thin',color:'black'}
                }
            }
            
        })

        let startIndex = report3.reduce((a,b) => a+b.child.length, 0)+10
        
        sheet2.mergeCells(`B${startIndex}:N${startIndex}`)
        sheet2.getCell(`B${startIndex}`).value="■ 원본서류"

        sheet2.mergeCells(`B${startIndex+1}:E${startIndex+1}`)
        sheet2.getCell(`B${startIndex+1}`).value = "구분"
        
        sheet2.getCell(`F${startIndex+1}`).value = "수량"

        sheet2.mergeCells(`G${startIndex+1}:N${startIndex+1}`)
        sheet2.getCell(`G${startIndex+1}`).value = "접수번호 (Agency Number)"
        
        Object.keys(report4).map((foo, fooIndex) => {
            sheet2.mergeCells(`B${startIndex+2+fooIndex*4+fooIndex}:E${startIndex+2+(fooIndex+1)*4+fooIndex}`)
            sheet2.getCell(`B${startIndex+2+fooIndex*4+fooIndex}`).value = foo
            
            sheet2.mergeCells(`F${startIndex+2+fooIndex*4+fooIndex}:F${startIndex+2+(fooIndex+1)*4+fooIndex}`)
            sheet2.getCell(`F${startIndex+2+fooIndex*4+fooIndex}`).value = report4[foo].length

            let cells = ['G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']

            report4[foo].map((code, codeIndex) => {
                let decimal = codeIndex%8
                let buxel = Math.floor(codeIndex/8)
                sheet2.getCell(`${cells[decimal]}${startIndex+2+fooIndex*4+fooIndex+buxel}`).value = code.registeration_number
            })
        })

        let cells = ["B","C","D","E","F","G","H","I","J","K","L","M","N"]

        for(var i = startIndex+1; i < startIndex+2+Object.keys(report4).length*5; i++) {
            cells.map((foo, fooIndex) => {
                sheet2.getCell(`${foo}${i}`).border = {
                    top: {style:'thin',color:'black'},
                    left: {style:'thin',color:'black'},
                    bottom: {style:'thin',color:'black'},
                    right: {style:'thin',color:'black'}
                }
            })
        }
        
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `XX 공인대행기관 사증접수대장 (XX LLC) ${moment().format('YYYY.MM.DD')}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    };
    
    return (
        <>
            <div className='flex justify-between items-center pb-2'>
                <div className='text-primary'>Тайлан</div>
            </div>
            <div className='bg-white rounded-lg p-4 mb-4 border'>
                <Form
                    form={filterForm}
                    fields={searchFields()}
                    layout="vertical"
                    onFinish={handleFilterSubmit}
                    className='gap-4'
                />
                <div className="flex justify-between gap-2 mt-4">
                    <Tooltip
                        placement="bottom"
                        title={"Excel-ээр татах"}
                    >
                        <Button
                            size='small'
                            type='primary'
                            icon={<PrinterOutlined className='text-base' />}
                            className='flex items-center border-green-500 hover:border-green-400 bg-green-500 hover:bg-green-400'
                            onClick={exportExcelFile} color='primary'
                        >
                            Татах
                        </Button>
                    </Tooltip>
                    <div className='flex gap-2 items-center'>
                        <Button className='flex items-center' size='small' type="default" onClick={() => filterForm.submit()}>
                            <SearchOutlined />Хайх
                        </Button>
                        <Button className='flex items-center' size='small' type="default" onClick={() => filterForm.resetFields()}>
                            <ClearOutlined />Цэвэрлэх
                        </Button>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 bg-white p-4 border rounded-md'>
                <Tabs
                    items={[
                        {
                            label: '대장 1',
                            key: 0,
                            children: <div className='flex flex-col gap-10'>
                                <Table
                                dataSource={report1}
                                columns={columns1}
                                setCurrentPage={setCurrentPage}
                                loading={loading}
                                defaultCheckedColumns={defaultCheckedColumns}
                                filterHide={true}
                                bordered
                                pagination={false}
                            />
                            <div></div>
                            <Table
                                dataSource={report2}
                                columns={columns2}
                                setCurrentPage={setCurrentPage}
                                loading={loading}
                                defaultCheckedColumns={defaultCheckedColumns}
                                filterHide={true}
                                bordered
                                pagination={false}
                                >

                            </Table>
                            </div>
                        },
                        {
                            label: '대장 2',
                            key: 2,
                            children: <div className='flex flex-col gap-10'>
                                <Table
                                    dataSource={tableReport3}
                                    columns={columns3}
                                    setCurrentPage={setCurrentPage}
                                    loading={loading}
                                    defaultCheckedColumns={defaultCheckedColumns}
                                    filterHide={true}
                                    bordered
                                    size='small'
                                    pagination={false}
                                />
                                <Table
                                    dataSource={tableReport4}
                                    columns={column4}
                                    setCurrentPage={setCurrentPage}
                                    loading={loading}
                                    defaultCheckedColumns={defaultCheckedColumns}
                                    filterHide={true}
                                    size='small'
                                    bordered
                                    pagination={false}
                                />
                            </div>
                        },
                    ]}>

                </Tabs>
                
                {/* <Table
                    dataTable={report2}
                    columns={columns}
                    setCurrentPage={setCurrentPage}
                    loading={loading}
                    defaultCheckedColumns={defaultCheckedColumns}
                    filterHide={true}
                    borderHide={true}
                    pagination={false}
                />
                <Table
                    dataTable={report3}
                    columns={columns}
                    setCurrentPage={setCurrentPage}
                    loading={loading}
                    defaultCheckedColumns={defaultCheckedColumns}
                    filterHide={true}
                    borderHide={true}
                    pagination={false}
                />
                <Table
                    dataTable={report4}
                    columns={columns}
                    setCurrentPage={setCurrentPage}
                    loading={loading}
                    defaultCheckedColumns={defaultCheckedColumns}
                    filterHide={true}
                    borderHide={true}
                    pagination={false}
                /> */}
            </div>
        </>
    );
};

export default Report;